<template>
  <div :class="`${position || 'default'} three-js-preview`" ref="parent-wrap">
    <canvas ref="webgl" style="height: 100%" className="webgl"></canvas>
    <div className="shadow_page">
      <div id="loading-text-intro"><p>Loading</p></div>
    </div>
  </div>
</template>

<script type="module">
import * as THREE from "three";
import { TWEEN } from "@/assets/js/three/jsm/libs/tween.module.min.js";
import { OBJLoader } from "@/assets/js/three/jsm/loaders/OBJLoader.js";
import { MTLLoader } from "@/assets/js/three/jsm/loaders/MTLLoader.js";
import Stats from "@/assets/js/three/jsm/libs/stats.module.js";
import { OrbitControls } from "@/assets/js/three/jsm/controls/OrbitControls.js";

export default {
  props: ["objUrl", "mtlUrl", "position", "followParentEleSize"],
  computed: {},
  methods: {
    initThree() {
      let camera,
        renderer,
        light1,
        light2,
        light3,
        light4,
        object,
        stats,
        scene,
        controls,
        loadingManager;
      const clock = new THREE.Clock();
      const _this = this;

      //   init
      loadingManager = new THREE.LoadingManager();

      init();
      animate();

      // // 初始化加载器
      // const manager = new THREE.LoadingManager();
      // // 此函数在加载开始时被调用
      loadingManager.onStart = function (url, itemsLoaded, itemsTotal) {
        console.log(
          "Started loading file: " +
            url +
            ".\nLoaded " +
            itemsLoaded +
            " of " +
            itemsTotal +
            " files."
        );
      };
      // 所有的项目加载完成后将调用此函数。默认情况下，该函数是未定义的，除非在构造函数中传入
      loadingManager.onLoad = function () {
        console.log("Loading complete!");
        // loading done and start to screenshot
        setTimeout(() => {
          shot();
          document.querySelector("#loading-text-intro").className += " hide";
        }, 1500);
      };
      // 此方法加载每一个项，加载完成时进行调用
      loadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
        console.log(
          "Loading file: " +
            url +
            ".\nLoaded " +
            itemsLoaded +
            " of " +
            itemsTotal +
            " files."
        );
        document.querySelector(
          "#loading-text-intro p"
        ).innerHTML = `loading...${((itemsLoaded / itemsTotal) * 100).toFixed(
          2
        )} %`;
      };
      // 此方法将在任意项加载错误时，进行调用
      loadingManager.onError = function (url) {
        console.log("There was an error loading " + url);
      };

      function init() {
        camera = new THREE.PerspectiveCamera(
          50,
          window.innerWidth / window.innerHeight,
          1,
          1000
        );
        camera.position.z = 100;
        scene = new THREE.Scene();

        // var OBJFile = "../source/avata1/eva.obj";
        // var MTLFile = "../source/avata1/eva.mtl";
        var OBJFile = _this.objUrl;
        var MTLFile = _this.mtlUrl;
        // MTL loader是加载皮肤文件用的

        new MTLLoader(loadingManager).load(MTLFile, function (materials) {
          materials.preload();
          new OBJLoader(loadingManager)
            .setMaterials(materials)
            .load(OBJFile, function (object) {
              object.scale.multiplyScalar(50);
              object.position.y = -30;
              scene.add(object);
            });
        });

        //lights
        scene.add(new THREE.AmbientLight(0x443333));

        const dirLight1 = new THREE.DirectionalLight(0xccccff, 2.5);
        dirLight1.position.set(-10, 5, -10);
        scene.add(dirLight1);
        const dirLight2 = new THREE.DirectionalLight(0xccccff, 2.5);
        dirLight2.position.set(10, 5, 10);
        scene.add(dirLight2);

        //renderer
        renderer = new THREE.WebGLRenderer({
          // canvas: document.querySelector("canvas.webgl"),
          canvas: _this.$refs.webgl,
          antialias: true,
          preserveDrawingBuffer: true,
        });
        renderer.setPixelRatio(window.devicePixelRatio);

        //   renderer.setSize(parentWrap.innerWidth, parentWrap.innerHeight);
        //   document.querySelector('.js-dialog-form>.el-dialog').appendChild(renderer.domElement);

        // control
        controls = new OrbitControls(camera, renderer.domElement);
        controls.target.set(0, 0.5, 0);
        controls.update();
        controls.enablePan = false;
        controls.enableDamping = true;

        // stats
        // stats = new Stats();

        //   window resize
        // document.body.appendChild(stats.dom);
        if (!_this.followParentEleSize) {
          onWindowResize();
          window.addEventListener("resize", onWindowResize);
        }
      }

      function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);
      }

      function animate() {
        requestAnimationFrame(animate);

        render();
        // stats.update();
      }

      function render() {
        const time = Date.now() * 0.0005;
        const delta = clock.getDelta();

        if (object) object.rotation.y -= 0.5 * delta;

        controls.update();
        renderer.render(scene, camera);
      }

      function shot() {
        const img = {
          thumbBase64: "",
          thumbSuffix: "",
        };
        renderer.render(scene, camera);
        let imgData = renderer.domElement.toDataURL("image/jpeg"); //这里可以选择png格式jpeg格式
        img.thumbBase64 = imgData;
        img.thumbSuffix = "jpeg";

        // let image = new Image();
        // image.src = imgData;
        // image.id = "image";
        // document.body.appendChild(image);
        if (_this._events["SaveThreeScreenshot"]) {
          _this.$emit("SaveThreeScreenshot", img);
        }
      }

      function downloadImage(imgUrl) {
        let a = $("<a></a>")
          .attr("href", imgUrl)
          .attr("download", "img.png")
          .appendTo("body");
        a[0].click();
        a.remove();
      }
    },
  },
  mounted() {
    this.initThree();
  },
};
</script>

<style lang="scss" scoped>
.three-js-preview {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  .webgl {
    width: 100%;
    height: 100%;
  }
  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
}
body {
  margin: 0;
  background-color: #000;
  color: #fff;
  font-family: Monospace;
  font-size: 13px;
  line-height: 24px;
  overscroll-behavior: none;
}

a {
  color: #ff0;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  cursor: pointer;
  text-transform: uppercase;
}

#info {
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 1; /* TODO Solve this in HTML */
}

a,
button,
input,
select {
  pointer-events: auto;
}

.lil-gui {
  z-index: 2 !important; /* TODO Solve this in HTML */
}

@media all and (max-width: 640px) {
  .lil-gui.root {
    right: auto;
    top: auto;
    max-height: 50%;
    max-width: 80%;
    bottom: 0;
    left: 0;
  }
}

#overlay {
  position: absolute;
  font-size: 16px;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
}

#overlay button {
  background: transparent;
  border: 0;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 18px;
  text-transform: uppercase;
  cursor: pointer;
}

#notSupported {
  width: 50%;
  margin: auto;
  background-color: #f00;
  margin-top: 20px;
  padding: 10px;
}

#loading-text-intro {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;

  font-family: sans-serif;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 100;
  color: #f9f0ec;
  background: radial-gradient(circle at center center, #5d5d5d 0, #090909 58%);
}
#loading-text-intro.hide {
  display: none;
}
</style>
