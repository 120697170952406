<template>
  <el-dialog
    class="js-dialog-form custom-style"
    :title="isModify ? '修改二维码' : '添加二维码'"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="600px"
    :show-close="false"
  >
    <div class="select-type">
      <label for="">选择类型：</label>
      <el-select v-model="type" placeholder="请选择类型">
        <el-option
          v-for="item in types"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <ul v-if="type === 0">
      <li v-for="(item, index) in textData" :key="index">
        <el-input
          v-model="textData[index].key"
          placeholder="请输入键值"
          size="small"
          style="max-width: 150px"
        ></el-input
        >：
        <el-input
          v-model="textData[index].val"
          placeholder="请输入值"
          size="small"
        ></el-input>
        <el-button
          style="margin-left: 10px"
          icon="el-icon-close"
          circle
          v-if="index !== 0"
          @click="() => removeRow(index)"
        ></el-button>
      </li>
      <li><el-button @click="addRow">添加行</el-button></li>
    </ul>
    <div class="uploader" v-if="type === 1">
      <label for="">上传文件：</label>
      <el-progress
        v-if="!!showText"
        style="width: 100%"
        :percentage="percentage"
        :color="customColor"
        :show-text="!!showText"
        :format="progressFormat"
      ></el-progress>
      <el-upload
        v-else
        class="avatar-uploader"
        action="#"
        :show-file-list="false"
        :auto-upload="false"
        :on-change="handleHttpRequest"
      >
        <i class="el-icon-s-claim" v-if="threeData"></i>
        <div v-else class="icon">
          <img src="@/assets/images/13.png" alt="" />
        </div>
      </el-upload>
    </div>
    <div class="uploader three-js-root" v-if="type === 1 && threeData">
      <label for="">3D预览：</label>
      <ThreeJsPreview
        v-if="dialogVisible && threeData.fullObjUrl"
        :objUrl="threeData.fullObjUrl"
        :mtlUrl="threeData.fullMtlUrl"
        position="left"
        :followParentEleSize="true"
        @SaveThreeScreenshot="SaveThreeScreenshot"
      />
      <el-empty v-else description="暂无模型"></el-empty>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm('')" size="small"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
const INIT_UPLOADING_STATE = ["文件上传中", "文件解压中", "截图上传中", ""];
import ThreeJsPreview from "./ThreeJsPreview.vue";

export default {
  name: "",
  props: ["value", "isModify", "currentItem", "currentType"],
  components: {
    ThreeJsPreview,
  },
  data() {
    return {
      showText: "",
      percentage: 0,
      currentBarStep: 0,
      customColor: "#409eff",
      progressBarMissions: [
        { color: "#f56c6c", duration: 1500, text: "文件上传中", pass: false },
        { color: "#e6a23c", duration: 3500, text: "文件解压中", pass: false },
        { color: "#5cb87a", duration: 1500, text: "截图上传中", pass: false },
        { color: "#1989fa", duration: 0, text: "", pass: false },
      ],
      types: [
        {
          label: "文本",
          value: 0,
        },
        {
          label: "文件",
          value: 1,
        },
      ],
      // 是否正在上传3d模型
      uploading3DModel: false,
      // objUrl:
      //   "https://3d-1305418344.cos.ap-beijing.myqcloud.com/avata1/eva.obj",
      // mtlUrl:
      //   "https://3d-1305418344.cos.ap-beijing.myqcloud.com/avata1/eva.mtl",
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    type: {
      get() {
        return this.currentType;
      },
      set(newVal) {
        this.$emit("updateParams", "currentType", newVal);
      },
    },
    textData: {
      get() {
        return this.currentItem.text;
      },
      set(newVal) {
        this.$emit("updateParams", "currentItem.text", newVal);
      },
    },
    threeData: {
      get() {
        return this.currentItem.three;
      },
      set(newVal) {
        this.$emit("updateParams", "currentItem.three", newVal);
      },
    },
  },
  mounted() {},
  updated() {},
  methods: {
    progressFormat(percentage) {
      return `${this.showText}:${percentage}%`;
    },
    resetAnimationParams() {
      this.showText = "";
      this.percentage = 0;
      this.progressBarMissions.map((item, index) => {
        this.progressBarMissions[index].pass = false;
      });
    },
    // 4次动画任务
    initProgressBarAnimation(step) {
      let currentMission = this.progressBarMissions[step];

      //  初始化;重置参数
      if (this.showText === "" && step === 0 && this.percentage === 0) {
        this.showText = currentMission.text;
        this.customColor = currentMission.color;

        this.progressBarMissions.map((item, index) => {
          this.progressBarMissions[index].pass = false;
        });
      }

      // 进度累加到100了,并且在范围内（通过showText班别），进一步
      if (
        this.showText &&
        this.percentage === 100 &&
        step < this.progressBarMissions.length
      ) {
        this.percentage = 0;
        step += 1;

        this.showText = this.progressBarMissions[step].text;
        this.customColor = this.progressBarMissions[step].color;
      }

      // 当走到最后一步的时候，停掉循环，初始化回去；只有最后一步的showText为""
      if (this.showText === "") {
        this.resetAnimationParams();
      } else {
        this.animationFrameLoop(step);
      }
    },
    // 一次动画循环
    animationFrameLoop(step) {
      // 这一步为了拿到最新的任务pass数据
      const currentMission = this.progressBarMissions[step];
      // 分解动画执行持续时间
      const duration = currentMission.duration / 5;
      // 递归
      setTimeout(() => {
        // 最多加到99
        if (this.percentage < 99) {
          this.percentage =
            this.percentage + 20 >= 99 ? 99 : this.percentage + 20;
        }
        // 等待异步pass通知;100的时候进一步
        else if (this.percentage === 99 && currentMission.pass) {
          this.percentage += 1;
          this.initProgressBarAnimation(step);
          return false;
        }
        // 不是100，继续递归
        this.animationFrameLoop(step);
      }, duration);
    },
    controlShow(show) {
      this.dialogVisible = show;
    },
    async handleHttpRequest(originFile) {
      const fileType = originFile.name.split(".")[1];

      // 3d模型大小超过10MB，低端机会明显cpu飙升卡顿；
      if (originFile.size / (1024 * 1024) >= 10) {
        this.$message.error("模型文件大小，不能超过10M！");

        return false;
      }
      if (!(fileType === "rar" || fileType === "zip")) {
        this.$message.error("只能上传zip、rar格式的文件！");

        return false;
      }

      // 每次上传手动清除
      if (this.type === 1) {
        this.uploading3DModel = true;
        this.resetAnimationParams();
        if (this.threeData) {
          this.threeData.fullMtlUrl = "";
          this.threeData.fullObjUrl = "";
        }
      }
      // 开启动画
      this.initProgressBarAnimation(0);
      await this.getUploadDir(fileType);

      const { url, file } = this.action;

      this.$axios({
        method: "put",
        url,
        data: originFile.raw,
        closeLoading: true,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          // 第2步通过
          this.progressBarMissions[1].pass = true;
          // this.$message.success("上传成功!");

          // 上传完文件后提交
          this.handleConfirm(file);
        }
      });
    },
    // 获取上传文件地址
    getUploadDir(suffix) {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "get",
          url: "api/csp/cos/v1/url/upload",
          params: {
            dirType: "upload",
            suffix,
          },
          closeLoading: true,
        })
          .then((response) => {
            let data = response.data;
            if (!data.status) {
              this.action = data.data;

              // 第1步通过
              this.progressBarMissions[0].pass = true;
            } else {
              this.$message({
                type: "error",
                message: data.msg,
                duration: 1000,
              });
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    handleCancel() {
      this.textData = "";
      this.dialogVisible = false;
      this.showText = "";
      // 新建-关闭的时候，需要移除临时对象
      if (!this.isModify) {
        this.removeExisting();
      }
    },
    // file: 当是模型上传的时候有file，模型按钮点击提交的时候，只需要隐藏掉窗口，清空数据就可以了；
    // 文本提交没有这个file，按照type区分；
    handleConfirm(file) {
      if (
        this.type === 0 &&
        this.textData.some((item) => !item.val || !item.key)
      ) {
        this.$message.error("每一项不得为空！");
        return false;
      }
      // 新建-上传文件；当且只有按钮提交走这个校验；上传文件通过
      if (this.type === 1 && !this.isModify && !file && !this.threeData) {
        this.$message.error("请上传文件！");
        return false;
      }
      // 文本-按钮提交
      if (this.type === 0) {
        this.$emit("handleAdd", this.textData, this.type);
      }
      // 3d模型 压缩包上传调用
      else if (this.type === 1 && file) {
        const NoRefreshList = true;

        this.$emit("handleAdd", file, this.type, NoRefreshList);
      }
      // 3d模型 按钮点击，清空当前数据;加载列表
      else {
        this.confirm3DModel();
        this.$emit("refreshList");
      }
      // 关闭窗口
      if (!file) {
        this.dialogVisible = false;
      }
    },
    addRow() {
      this.textData.push({ key: "", value: "", number: this.textData.length });
    },
    removeRow(index) {
      this.textData.splice(index, 1);
    },
    // 只有重新上传模型的时候，上传截图
    SaveThreeScreenshot(image) {
      if (!this.uploading3DModel) {
        return false;
      }
      // 每次上传一次3d模型，开启一次截图
      this.uploading3DModel = false;

      const form = {
        data: {
          ...image,
          qrLibId: this.threeData.qrLibId,
          type: this.currentType,
        },
      };

      this.$axios({
        method: "put",
        url: `api/csp/qr/v1/qr/${this.threeData.id}`,
        data: form,
        closeLoading: true,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.action = data.data;
          // 第3步通过
          this.progressBarMissions[2].pass = true;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 新建的时候，点击取消和关闭，要删除临时存在后台的对象; 隐形操作
    removeExisting() {
      if (!this.threeData.id) return false;
      this.$axios({
        method: "delete",
        url: `api/csp/qr/v1/qr/${this.threeData.id}`,
      }).then(() => {});
    },
    confirm3DModel() {
      this.$axios({
        method: "put",
        url: `api/csp/qr/v1/qr/confirm/${this.threeData.id}`,
      }).then(() => {
        this.threeData = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-type,
.uploader {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;

  label {
    min-width: 100px;
  }
}
.el-icon-s-claim {
  font-size: 110px;
}
.three-js-root {
  height: 220px;
  padding-bottom: 0;
  .three-js-preview {
    flex: 1;
  }
}
.custom-style {
}
</style>
