var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview-model" },
    [
      _vm.fullObjUrl
        ? _c("ThreeJsPreview", {
            attrs: { objUrl: _vm.fullObjUrl, mtlUrl: _vm.fullMtlUrl },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }