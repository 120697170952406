<template>
  <div class="preview-model">
    <ThreeJsPreview
      v-if="fullObjUrl"
      :objUrl="fullObjUrl"
      :mtlUrl="fullMtlUrl"
    />
  </div>
</template>

<script>
import ThreeJsPreview from "./ThreeJsPreview.vue";

export default {
  data() {
    return {
      fullObjUrl: "",
      fullMtlUrl: "",
    };
  },
  components: {
    ThreeJsPreview,
  },
  mounted() {
    const { mtl, obj } = this.$route.query;
    this.fullObjUrl = obj;
    this.fullMtlUrl = mtl;

    console.log(this.$route.query);
  },
};
</script>

<style lang="scss">
.preview-model {
  width: 100%;
  height: 100vh;
}
</style>
