<template>
  <div class="js-common-wrap js-car-reco">
    <div class="js-common-head">
      <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">车辆信息</label>
      </div>
    </div>
    <div class="js-common-content car-infos">
      <ul class="info-list">
        <li><label>采集照片：</label><img class="img" :src="carInfo.captureUrl" alt=""></li>
        <li><label>车牌号码：</label><span class="carnum">{{ carInfo.licensePlate }}</span></li>
        <li><label>警示：</label>
          <span>
            <el-button v-if="carInfo.status" type="danger" size="small">有</el-button>
            <el-button v-else type="primary" size="small">无</el-button>
          </span>
        </li>
        <li><label>车辆型号：</label><span>{{ carInfo.lpr.carModel || '无' }}</span></li>
        <li><label>车辆颜色：</label><span>{{ carInfo.lpr.carColor || '无' }}</span></li>
        <li><label>身份信息：</label><span>{{ carInfo.lpr.idcard || '无' }}</span></li>
        <li><label>最近识别时间：</label><span>{{ carInfo.lastRecognTime || '无' }}</span></li>
        <li><label>识别时间：</label><span>{{ carInfo.recognTime || '无' }}</span></li>
        <li><label>创建人：</label><span>{{ carInfo.createdName || '无' }}</span></li>
        <li><label>反馈：</label><span>{{ carInfo.remark || '无 '}}</span></li>
      </ul>
      <div class="js-table-box">
        <el-table class="js-table" :data="carInfo.records" empty-text="暂无数据">
          <el-table-column label="采集照片">
            <template slot-scope="scope">
              <img class="img" :src="scope.row.captureUrl" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="recognTime" label="识别时间"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-delete" @click.stop="deleteItem(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <common-delete v-model="deleteDialogVisible" @handleDelete="handleDelete"></common-delete>
  </div>
</template>
<script>
import CommonDelete from '@/components/CommonDelete'
export default {
  name: '',
  components: {
    CommonDelete
  },
  data() {
    return {
      carInfo: {
        captureUrl:'',
        licensePlate:'',
        status:'',
        lpr:{
          carModel:'',
          carColor:'',
          idcard:'',
        },
        lastRecognTime:'',
        recognTime:'',
        createdName:'',
        remark:'',
        records:[{
          captureUrl:'',
          recognTime:'',
        }]
      },
      deleteDialogVisible: false,
      currentID: '',
      currentItem: ''
    }
  },
  mounted() {
    this.currentID = this.$route.query.id
    this.getDetails()
  },
  methods: {
    getDetails(){
      this.$axios({
        method: 'get',
        url: `api/csp/lpr/v1/lprRecord/${this.currentID}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.carInfo = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    deleteItem(item){
      this.currentItem = item
      this.deleteDialogVisible = true
    },
    handleDelete(){
      this.$axios({
        method: 'delete',
        url: `api/csp/lpr/v1/lprRecord/${this.currentItem.id}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.deleteDialogVisible = false
          this.$message({
            type: 'success',
            message: '删除成功！',
            duration: 1000
          })
          this.getDetails()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.car-infos{
  margin-top: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
}
.carnum{
  width: 150px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  background: #1d85fc;
  border-radius: 2px;
  text-align: center;
  color: #fff;
}
.info-list{
  padding: 30px 60px;
  background: #fff;
  font-size: 14px;
  li{
    margin-bottom: 10px;
    line-height: 32px;
  }
  label{
    width: 150px;
    display: inline-block;
  }
  .img{
    width: 150px;
    height: 30px;
    vertical-align: middle;
  }
}
.js-table{
  margin-top: 10px;
  &-box{
    flex: 1;
  }
}
.js-car-reco{
  .js-common-content{
    .js-table {
      .img{
        width: 150px;
        height: 30px;
        vertical-align: middle;
      }
    }
  }
}
</style>
