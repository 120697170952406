var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-car-reco" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "back-btn",
              attrs: {
                icon: "el-icon-arrow-left",
                size: "small",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.$goBack },
            }),
            _c("label", { staticClass: "label" }, [_vm._v("车辆信息")]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "js-common-content car-infos" }, [
        _c("ul", { staticClass: "info-list" }, [
          _c("li", [
            _c("label", [_vm._v("采集照片：")]),
            _c("img", {
              staticClass: "img",
              attrs: { src: _vm.carInfo.captureUrl, alt: "" },
            }),
          ]),
          _c("li", [
            _c("label", [_vm._v("车牌号码：")]),
            _c("span", { staticClass: "carnum" }, [
              _vm._v(_vm._s(_vm.carInfo.licensePlate)),
            ]),
          ]),
          _c("li", [
            _c("label", [_vm._v("警示：")]),
            _c(
              "span",
              [
                _vm.carInfo.status
                  ? _c(
                      "el-button",
                      { attrs: { type: "danger", size: "small" } },
                      [_vm._v("有")]
                    )
                  : _c(
                      "el-button",
                      { attrs: { type: "primary", size: "small" } },
                      [_vm._v("无")]
                    ),
              ],
              1
            ),
          ]),
          _c("li", [
            _c("label", [_vm._v("车辆型号：")]),
            _c("span", [_vm._v(_vm._s(_vm.carInfo.lpr.carModel || "无"))]),
          ]),
          _c("li", [
            _c("label", [_vm._v("车辆颜色：")]),
            _c("span", [_vm._v(_vm._s(_vm.carInfo.lpr.carColor || "无"))]),
          ]),
          _c("li", [
            _c("label", [_vm._v("身份信息：")]),
            _c("span", [_vm._v(_vm._s(_vm.carInfo.lpr.idcard || "无"))]),
          ]),
          _c("li", [
            _c("label", [_vm._v("最近识别时间：")]),
            _c("span", [_vm._v(_vm._s(_vm.carInfo.lastRecognTime || "无"))]),
          ]),
          _c("li", [
            _c("label", [_vm._v("识别时间：")]),
            _c("span", [_vm._v(_vm._s(_vm.carInfo.recognTime || "无"))]),
          ]),
          _c("li", [
            _c("label", [_vm._v("创建人：")]),
            _c("span", [_vm._v(_vm._s(_vm.carInfo.createdName || "无"))]),
          ]),
          _c("li", [
            _c("label", [_vm._v("反馈：")]),
            _c("span", [_vm._v(_vm._s(_vm.carInfo.remark || "无 "))]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "js-table-box" },
          [
            _c(
              "el-table",
              {
                staticClass: "js-table",
                attrs: { data: _vm.carInfo.records, "empty-text": "暂无数据" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "采集照片" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("img", {
                            staticClass: "img",
                            attrs: { src: scope.row.captureUrl, alt: "" },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "recognTime", label: "识别时间" },
                }),
                _c("el-table-column", {
                  attrs: { label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("i", {
                            staticClass: "el-icon-delete",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.deleteItem(scope.row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("common-delete", {
        on: { handleDelete: _vm.handleDelete },
        model: {
          value: _vm.deleteDialogVisible,
          callback: function ($$v) {
            _vm.deleteDialogVisible = $$v
          },
          expression: "deleteDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }