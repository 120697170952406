var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-car-reco" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "back-btn",
              attrs: {
                icon: "el-icon-arrow-left",
                size: "small",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.$goBack },
            }),
            _c("label", { staticClass: "label" }, [_vm._v("二维码记录")]),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.add(0, "")
                  },
                },
              },
              [_vm._v("添加二维码")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "类型", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.typeTranslate(scope.row)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "qrcode", label: "二维码", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("vue-qr", {
                          attrs: {
                            qid: "qrid",
                            text: _vm.transform(scope.row),
                            size: _vm.qrcode.size,
                            bgSrc: _vm.qrcode.bgSrc,
                            logoSrc: _vm.qrcode.logoSrc,
                            callback: _vm.qrcode.callBack,
                            logoScale: _vm.qrcode.logoScale,
                            colorDark: _vm.qrcode.colorDark,
                            colorLight: _vm.qrcode.colorLight,
                            backgroundColor: _vm.qrcode.backgroundColor,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "infoList", label: "二维码信息", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type === 0
                          ? _c(
                              "div",
                              { staticClass: "type-text" },
                              _vm._l(
                                scope.row.infoList,
                                function (item, index) {
                                  return _c("p", { key: index }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.key + ":" + item.val) +
                                        " "
                                    ),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        scope.row.type === 1
                          ? _c("div", { staticClass: "type-file" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: scope.row.fullFileUrl,
                                    target: "__blank",
                                  },
                                },
                                [_vm._v("下载")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fullThumbUrl",
                  label: "模型截图",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.fullThumbUrl
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "点击跳转至模型预览页面",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "preview",
                                  attrs: {
                                    src: scope.row.fullThumbUrl,
                                    alt: "",
                                    title: "点击跳转预览3D模型",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.to3DPreview(scope.row)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _c("p", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "gmtCreated", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "gmtUpdated",
                  label: "上次修改时间",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-edit-outline",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.add(1, scope.row)
                            },
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteItem(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
      _c("car-import", {
        attrs: { id: _vm.currentID },
        on: { uploadSuccess: _vm.uploadSuccess },
        model: {
          value: _vm.carImportDialogVisible,
          callback: function ($$v) {
            _vm.carImportDialogVisible = $$v
          },
          expression: "carImportDialogVisible",
        },
      }),
      _c("qrcode-add", {
        ref: "qrcodeAdd",
        attrs: {
          currentItem: _vm.currentItem,
          isModify: _vm.isModify,
          currentType: _vm.currentType,
        },
        on: {
          handleAdd: _vm.handleAdd,
          updateParams: _vm.updateParams,
          refreshList: _vm.getList,
        },
        model: {
          value: _vm.addDialogVisible,
          callback: function ($$v) {
            _vm.addDialogVisible = $$v
          },
          expression: "addDialogVisible",
        },
      }),
      _c("common-delete", {
        on: { handleDelete: _vm.handleDelete },
        model: {
          value: _vm.deleteDialogVisible,
          callback: function ($$v) {
            _vm.deleteDialogVisible = $$v
          },
          expression: "deleteDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }