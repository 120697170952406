var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form custom-style",
      attrs: {
        title: _vm.isModify ? "修改二维码" : "添加二维码",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "600px",
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "select-type" },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("选择类型：")]),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择类型" },
              model: {
                value: _vm.type,
                callback: function ($$v) {
                  _vm.type = $$v
                },
                expression: "type",
              },
            },
            _vm._l(_vm.types, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm.type === 0
        ? _c(
            "ul",
            [
              _vm._l(_vm.textData, function (item, index) {
                return _c(
                  "li",
                  { key: index },
                  [
                    _c("el-input", {
                      staticStyle: { "max-width": "150px" },
                      attrs: { placeholder: "请输入键值", size: "small" },
                      model: {
                        value: _vm.textData[index].key,
                        callback: function ($$v) {
                          _vm.$set(_vm.textData[index], "key", $$v)
                        },
                        expression: "textData[index].key",
                      },
                    }),
                    _vm._v("： "),
                    _c("el-input", {
                      attrs: { placeholder: "请输入值", size: "small" },
                      model: {
                        value: _vm.textData[index].val,
                        callback: function ($$v) {
                          _vm.$set(_vm.textData[index], "val", $$v)
                        },
                        expression: "textData[index].val",
                      },
                    }),
                    index !== 0
                      ? _c("el-button", {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { icon: "el-icon-close", circle: "" },
                          on: { click: () => _vm.removeRow(index) },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _c(
                "li",
                [
                  _c("el-button", { on: { click: _vm.addRow } }, [
                    _vm._v("添加行"),
                  ]),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.type === 1
        ? _c(
            "div",
            { staticClass: "uploader" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("上传文件：")]),
              !!_vm.showText
                ? _c("el-progress", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      percentage: _vm.percentage,
                      color: _vm.customColor,
                      "show-text": !!_vm.showText,
                      format: _vm.progressFormat,
                    },
                  })
                : _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "#",
                        "show-file-list": false,
                        "auto-upload": false,
                        "on-change": _vm.handleHttpRequest,
                      },
                    },
                    [
                      _vm.threeData
                        ? _c("i", { staticClass: "el-icon-s-claim" })
                        : _c("div", { staticClass: "icon" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/13.png"),
                                alt: "",
                              },
                            }),
                          ]),
                    ]
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.type === 1 && _vm.threeData
        ? _c(
            "div",
            { staticClass: "uploader three-js-root" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("3D预览：")]),
              _vm.dialogVisible && _vm.threeData.fullObjUrl
                ? _c("ThreeJsPreview", {
                    attrs: {
                      objUrl: _vm.threeData.fullObjUrl,
                      mtlUrl: _vm.threeData.fullMtlUrl,
                      position: "left",
                      followParentEleSize: true,
                    },
                    on: { SaveThreeScreenshot: _vm.SaveThreeScreenshot },
                  })
                : _c("el-empty", { attrs: { description: "暂无模型" } }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.handleConfirm("")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }