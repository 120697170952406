var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "parent-wrap",
      class: `${_vm.position || "default"} three-js-preview`,
    },
    [
      _c("canvas", {
        ref: "webgl",
        staticStyle: { height: "100%" },
        attrs: { className: "webgl" },
      }),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { className: "shadow_page" } }, [
      _c("div", { attrs: { id: "loading-text-intro" } }, [
        _c("p", [_vm._v("Loading")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }