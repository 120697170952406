<template>
  <div class="js-common-wrap js-car-reco">
    <div class="js-common-head">
      <div>
        <el-button
          class="back-btn"
          @click="$goBack"
          icon="el-icon-arrow-left"
          size="small"
          type="primary"
          plain
        ></el-button>
        <label class="label">二维码记录</label>
      </div>
      <div>
        <!-- <el-button type="primary" size="small" @click="handleImport"
          >批量导入</el-button
        > -->
        <el-button type="primary" size="small" @click="add(0, '')"
          >添加二维码</el-button
        >
      </div>
    </div>
    <div class="js-common-content">
      <el-table
        class="js-table"
        :data="tableData"
        height="100%"
        empty-text="暂无数据"
      >
        <el-table-column prop="id" label="ID" width="150"></el-table-column>
        <el-table-column prop="type" label="类型" width="150"
          ><template slot-scope="scope">
            {{ typeTranslate(scope.row) }}
          </template></el-table-column
        >
        <el-table-column prop="qrcode" label="二维码" width="150">
          <template slot-scope="scope">
            <vue-qr
              qid="qrid"
              :text="transform(scope.row)"
              :size="qrcode.size"
              :bgSrc="qrcode.bgSrc"
              :logoSrc="qrcode.logoSrc"
              :callback="qrcode.callBack"
              :logoScale="qrcode.logoScale"
              :colorDark="qrcode.colorDark"
              :colorLight="qrcode.colorLight"
              :backgroundColor="qrcode.backgroundColor"
            ></vue-qr>
          </template>
        </el-table-column>
        <el-table-column prop="infoList" label="二维码信息" width="200">
          <template slot-scope="scope">
            <div class="type-text" v-if="scope.row.type === 0">
              <p v-for="(item, index) in scope.row.infoList" :key="index">
                {{ item.key + ":" + item.val }}
              </p>
            </div>
            <div class="type-file" v-if="scope.row.type === 1">
              <a :href="scope.row.fullFileUrl" target="__blank">下载</a>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="fullThumbUrl" label="模型截图" width="200">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="点击跳转至模型预览页面"
              placement="bottom"
              v-if="scope.row.fullThumbUrl"
            >
              <img
                class="preview"
                :src="scope.row.fullThumbUrl"
                alt=""
                title="点击跳转预览3D模型"
                @click="to3DPreview(scope.row)"
              />
            </el-tooltip>

            <p v-else>-</p>
          </template>
        </el-table-column>

        <el-table-column
          prop="gmtCreated"
          label="创建时间"
          width="160"
        ></el-table-column>
        <el-table-column
          prop="gmtUpdated"
          label="上次修改时间"
          width="160"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline" @click.stop="add(1, scope.row)"></i>
            <i class="el-icon-delete" @click.stop="deleteItem(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination
      v-model="pageOptions"
      @curPageChange="handleCurPageChange"
    ></common-pagination>
    <car-import
      v-model="carImportDialogVisible"
      :id="currentID"
      @uploadSuccess="uploadSuccess"
    ></car-import>
    <qrcode-add
      ref="qrcodeAdd"
      v-model="addDialogVisible"
      :currentItem="currentItem"
      :isModify="isModify"
      :currentType="currentType"
      @handleAdd="handleAdd"
      @updateParams="updateParams"
      @refreshList="getList"
    ></qrcode-add>
    <common-delete
      v-model="deleteDialogVisible"
      @handleDelete="handleDelete"
    ></common-delete>
  </div>
</template>
<script>
import CommonPagination from "@/components/CommonPagination";
import CarImport from "@/components/CarImport";
import QrcodeAdd from "@/views/Qrcode/QrcodeAdd";
import CommonDelete from "@/components/CommonDelete";
import VueQr from "vue-qr";

export default {
  name: "",
  components: {
    CommonPagination,
    CarImport,
    QrcodeAdd,
    CommonDelete,
    VueQr,
  },
  data() {
    return {
      qrcode: {
        href: "",
        text: "https://github.com/MuGuiLin", // 二维码内容
        size: 120, // 二维码宽高尺寸, 长宽一致, 包含外边距
        bgSrc: "", // 嵌入背景图地址，
        logoSrc: "", // 嵌入至二维码中心的 LOGO 地址
        margin: 0, // 二维码图像的外边距, 默认20px
        colorDark: "#000", // 实点的颜色
        colorLight: "#fff", // 空白区的颜色
        backgroundColor: "#EEE", // 背景色
        logoScale: 0.15, // 中间图的尺寸，不要设太大，太大会导致扫码失败的 默认0.2
        dotScale: 0.35, // 数据区域点缩小比例,默认为0.35
      },
      tableData: [],
      carImportDialogVisible: false,
      addDialogVisible: false,
      deleteDialogVisible: false,
      pageOptions: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      isModify: "",
      currentID: "",
      // three 为3d模型数据；text为文本列表数据
      currentItem: { three: "", text: "" },
      currentType: 0,
      isWarning: 0,
      // 储存模型的临时id
      temproryId: "",
    };
  },
  mounted() {
    this.currentID = this.$route.query.id;
    this.getList();
  },
  methods: {
    updateParams(type, val) {
      if (type === "currentItem.text") {
        this.currentItem.text = val;
      } else if (type === "currentItem.three") {
        this.currentItem.three = val;
      } else {
        this[type] = val;
      }
    },
    typeTranslate(row) {
      if (!row) {
        return "";
      }
      switch (row.type) {
        case 0:
          return "文本";
        case 1:
          return "文件";
      }
    },
    transform(row) {
      const key = this.$store.state.userInfo.tenantCode;
      return key + ":" + row.id;
    },
    getList() {
      this.$axios({
        method: "get",
        url: `api/csp/qr/v1/qr/lib/${this.currentID}`,
        params: {
          lprLibId: this.currentID,
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage,
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.tableData = data.data.rows;
          this.pageOptions.total = data.data.total;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 分页
    handleCurPageChange() {
      this.getList();
    },
    add(flag, item) {
      this.isModify = flag;
      this.addDialogVisible = true;

      if (item) {
        this.currentType = item.type;
        // 3d模型数据
        this.currentItem.three = this.$Utils.deepCopy(item);
        // 文本数据
        this.currentItem.text =
          item.infoList && this.$Utils.deepCopy(item.infoList);
      } else {
        // reset params
        this.currentItem.text = [{ val: "", key: "", number: 0 }];
        this.currentItem.three = null;
        this.currentType = 0;
      }
    },
    handleAdd(params, type = 0, NoRefreshList = false) {
      let method = "";
      let url = "";
      if (this.isModify) {
        method = "put";
        url = `api/csp/qr/v1/qr/${this.currentItem.three.id}`;
      } else {
        method = "post";
        url = "api/csp/qr/v1/qr/";
      }
      const form = {
        data: {
          qrLibId: this.currentID,
          type,
        },
      };
      if (type === 0) {
        form.data.infoList = params;
      } else if (type === 1) {
        form.data.fileUrl = params;
      }
      this.$axios({
        method: method,
        url: url,
        data: form,
        closeLoading: NoRefreshList,
      }).then(({ data }) => {
        if (!data.status) {
          this.$message({
            type: "success",
            message: "新增成功！",
            duration: 1000,
          });

          this.pageOptions.page = 1;

          !NoRefreshList &&
            setTimeout(() => {
              this.getList();
            }, 1000);

          // 如果类型为压缩包的话，赋值2个文件地址
          if (type === 1) {
            try {
              const { fullMtlUrl, fullObjUrl, id } = data.data;

              this.currentItem.three = {
                ...this.currentItem.three,
                fullMtlUrl,
                fullObjUrl,
                id,
              };

              console.log(this.currentItem.three);
            } catch (e) {}
          }
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    deleteItem(item) {
      this.currentItem.text = item;
      this.deleteDialogVisible = true;
    },
    handleDelete() {
      this.$axios({
        method: "delete",
        url: `api/csp/qr/v1/qr/${this.currentItem.text.id}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.deleteDialogVisible = false;
          this.$message({
            type: "success",
            message: "删除成功！",
            duration: 1000,
          });
          this.pageOptions.page = 1;
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    handleImport() {
      this.carImportDialogVisible = true;
    },
    handleRowClick(row) {
      // this.$router.push({
      //   path: `/aiRecognition/carData/carDetails`,
      //   query: {
      //     id: row.id,
      //   },
      // });
    },
    uploadSuccess() {
      this.carImportDialogVisible = false;
      this.getList();
    },
    changeWarning() {
      this.$axios({
        method: "post",
        url: "api/csp/lpr/v1/lpr/warning",
        data: {
          data: {
            lprLibId: this.currentID,
            status: this.isWarning ? 1 : 0,
          },
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.$message({
            type: "success",
            message: "状态修改成功！",
            duration: 1000,
          });
          this.pageOptions.page = 1;
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    to3DPreview(row) {
      const obj = row.fullObjUrl;
      const mtl = row.fullMtlUrl;

      this.$router.push({
        path: `/aiRecognition/qrcode/3d-preview`,
        query: {
          obj,
          mtl,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/recognition.scss";
.preview {
  width: 200px;
  height: 112.5px;
}
</style>
